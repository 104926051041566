<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-divisions-taxi :options="bodyTypes" @editForm="editForm" />
    </div>
    <modal-divisions-taxi :divisions="divisions" @refresh="refresh" />
    <modal-divisions-taxi-edit :id="id" :divisions="divisions" @refresh="refresh" />
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
  </div>
</template>
<script>
import ModalDivisionsTaxi from "@/views/component/Modal/ModalDivisionsTaxi/ModalDivisionsTaxi.vue";
import ModalDivisionsTaxiEdit from "@/views/component/Modal/ModalDivisionsTaxi/ModalDivisionsTaxiEdit.vue";
import tableDivisionsTaxi from "@/views/component/Table/tableDivisionsTaxi.vue";
export default {
  components: {
    ModalDivisionsTaxi,
    tableDivisionsTaxi,
    ModalDivisionsTaxiEdit,
  },
  data() {
    return {
      bodyTypes: [],
      divisions: [],
      showPreloader: false,
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Название" },
        { key: "is_active", label: "Статус" },
        { key: "date_time", label: "Создан" },
      ],
      selectedBodyType: null,
      id: null,
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
    this.getdivisions();
  },
  methods: {
    editForm(id) {
      this.id = id.id
      setTimeout(this.openModal, 0);
    },
    openModal() {
      this.$bvModal.show('DivisionsTaxiEdit')
    },
    getdivisions() {
      this.$http.get("/divisions/data", { params: { is_active: true } }).then((res) => {
        this.divisions = res.data;
      });
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.bodyTypes = res.data;
          this.$store.commit("pageData/setdataCount", this.bodyTypes.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    refresh() {
      this.showPreloader = true;
      this.$http
        .get("divisions")
        .then((res) => {
          this.bodyTypes = res.data;
          this.$store.commit("pageData/setdataCount", this.bodyTypes.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        })
        .catch((err) => {});
    },
    sendToParent(tableData) {
      this.bodyTypes = tableData;
      this.$store.commit("pageData/setdataCount", this.bodyTypes.length);
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        let params = JSON.parse(localStorage.getItem(this.$route.name + "data"));
        this.$http.get(`${this.$route.name}`, { params: params }).then((res) => {
          this.bodyTypes = res.data;
          this.$store.commit("pageData/setdataCount", this.bodyTypes.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
