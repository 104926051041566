<template>
    <div>
        <b-modal 
        id="DivisionsTaxiEdit" 
        title="Редактирование подразделения такси" 
        size="s" 
        hide-footer 
        no-enforce-focus 
        :no-close-on-backdrop="true"
        @show="getDivision"
        >
            <b-row class="mb-1">
                <b-col cols="12" class="mt-1">
                    <label for="">ID города</label>
                    <v-select
                    v-model="bodyType.city_id"
                    :options="divisions.cities"
                    :reduce="divisions => divisions.id"
                    label="name_ru"
                    placeholder="Города"
                    >
                    </v-select>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="12">
                    <label for="">ID организации</label>
                    <v-select
                    v-model="bodyType.organization_id"
                    :options="divisions.organizations"
                    :reduce="divisions => divisions.id"
                    placeholder="Oрганизации"
                    label="name"
                    ></v-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                   <b-form-checkbox v-model="bodyType.is_active" class="custom-control-primary">
                    {{ bodyType.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editBodyType"
                    >
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
    components: {
        ToastificationContent,
        vSelect,
    },
    props: ['divisions','id'],
    data(){
        return {
            bodyType: {
                city_id: null,
                organization_id: null,
                is_active: true,
                id: this.id
            },
            divisionsSelectDisabled: false,
            submitButtonDisabled: false,
        }
    },
    methods: {
        clearData() {
            this.bodyType = {
                city_id: null,
                organization_id: null,
                is_active: true,
            }
        },
        closeModal() {
            this.$bvModal.hide('DivisionsTaxiEdit')
            this.submitButtonDisabled = false
        },
        editBodyType() {
            this.submitButtonDisabled = true
            let data = {
                city_id: this.bodyType.city_id,
                organization_id: this.bodyType.organization_id,
                is_active: this.bodyType.is_active == true ? 1 : 0
            }
            this.$http.patch(`/divisions/${this.bodyType.id}`, data)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Подразделение изменено!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        getDivision() {
                this.$http.get(`/divisions/${this.id}/edit`)
                .then(res => {
                    this.bodyType = res.data
                    this.bodyType.is_active = res.data.is_active === 1 ? true : false
                })
            },
    },
}
</script>