<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Добавить подразделение" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row class="mb-1">
                <b-col cols="12" class="mt-1">
                    <label for="">Название города</label>
                    <v-select
                    v-model="bodyType.city_id"
                    :options="divisions.cities"
                    :reduce="divisions => divisions.id"
                    label="name_ru"
                    placeholder="Города"
                    >
                    </v-select>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="12">
                    <label for="">ID огранизации</label>
                    <v-select
                    v-model="bodyType.organization_id"
                    :options="divisions.organizations"
                    :reduce="divisions => divisions.id"
                    placeholder="Oгранизации"
                    label="name"
                    ></v-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                   <b-form-checkbox v-model="bodyType.is_active" class="custom-control-primary">
                    {{ bodyType.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createBodyType">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
    components: {
        ToastificationContent,
        vSelect,
    },
    props: ['divisions'],
    data(){
        return {
            bodyType: {
                city_id: null,
                organization_id: null,
                is_active: true
            },
            submitButtonDisabled: false,
            divisionsSelectDisabled: false,
        }
    },
    methods: {
        clearData(){
            this.bodyType = {
                city_id: null,
                organization_id: null,
                is_active: true
            }
        },
        closeModal() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
        createBodyType() {
            this.submitButtonDisabled = true
            this.$http.post('/divisions', this.bodyType)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Подразделение создано!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        
    }
}
</script>